<template>
  <div class="success-wrapper text-center">
    <v-icon
      :icon="mdiCloseCircle"
      :size="100"
      color="red"
      class="mb-4"
    />
    <h1 class="view-heading text-h5 mb-4">{{ $translate('checkinDeniedHeading') }}</h1>
    <div class="checked-in-text">
      <p class="my-4">
        {{ reason }}
      </p>
    </div>
  </div>
</template>
<script setup lang="ts">
import { mdiCloseCircle } from '@mdi/js';
import { computed } from 'vue';
const props = defineProps({
  reason: {
    type: String,
    required: false,
    default: () => '',
  },
});
const reason = computed(() => window.atob(props.reason));
</script>
<style lang="scss" scoped>
.success-wrapper {
  margin-top: 33vh;
}
</style>